'use strict'

###*
 # @ngdoc object
 # @name users.controller:DataTableCtrl

 # @description

###
class DataTableCtrl
  ### @ngInject ###
  constructor: (
    entityManager
    pageTitle
    pageDescription
    deleteBoxTitle
    deleteBoxBody
    AddBoxTitle
    editBoxTitle
    loadFilterParams
    UserPermissions
    $log
    $mdDialog
    FormDialog
    ConfirmBox
    $q
    $filter
    $sce
    $rootScope
    $state
    MundoHelper
    _
    mundoAuditPermission
    MundoActions
  ) ->
    init = () =>

      @items = []
      @user = {}
      @selected = []

      @pageTitle = pageTitle
      @pageDescription = pageDescription
      @loadFilterParams = loadFilterParams

      @multiple = entityManager.conf.multiple
      @query = entityManager.defaultQuery
      @schema = entityManager.getListSchema()
      @detailTemplate = entityManager.getDetailTemplateUrl()
      @extraActions = entityManager.getExtraActions()
      @schemaLinks = if entityManager.getSchemaLinks? then entityManager.getSchemaLinks() else []
      @detailEditActions = entityManager.getDetailEditActions()
      @tableActions = entityManager.getTableActions()
      @templateOptions = if entityManager.templateOptions then entityManager.templateOptions else {}

      @showInlineDetail = showInlineDetail
      @getDetailTemplate = getDetailTemplate

      @load = load
      @onSearch = onSearch
      @onPaginate = onPaginate
      @onReorder = onReorder

      @openSearch = openSearch
      @closeSearch = closeSearch

      @openAddDialog = openAddDialog
      @openEditDialog = openEditDialog
      @openDetailDialog = openDetailDialog
      @openDetailEditDialog = openDetailEditDialog
      @openAuditDialog = openAuditDialog
      @deleteSelected = deleteSelected
      @deleteSingle = deleteSingle

      @getCsvContent = getCsvContent
      @getCsvHeader = getCsvHeader

      @helper = new MundoHelper entityManager

      @isEditable = @helper.checkAction 'update'
      @isDeletable = @helper.checkAction 'delete'
      @isCreatable = @helper.checkAction 'create'
      @isPrintable = @helper.checkAction 'print'
      @isRefreshable = @helper.checkAction 'refresh'
      @isSearchable = (@helper.checkAction 'search') and (entityManager.conf.searchFields.length > 0)
      @isAuditable = @helper.checkAction 'audit'

      @hasEditPermission = @helper.hasPermission
      @hasAuditPermission = -> @helper.hasPermission mundoAuditPermission
      @hasPermission = -> @helper.hasPermission

    ###########

#   list

    onSearch = () =>
      @load(angular.extend(@query, {page: 1}))

    onPaginate = (page, limit) =>
      @load(angular.extend(@query, {page: page, limit: limit}))

    onReorder = (order) =>
      @load(angular.extend(@query, {sort: order}))

    load = (query = @query) =>
      @selected = []
      @promise = entityManager.getList(query, @loadFilterParams)
        .then (data) =>
          @items = data

#   search

    openSearch = =>
      @query.search.active = true

    closeSearch= =>
      @query.search.active = false
      @query.search.query = ''
      @load()

#   CRUD
    openAddDialog = =>
      FormDialog.showFormDialog(AddBoxTitle, entityManager)
      .then( =>
        @load()
      (value) =>
        if value
          @load()
      )

    openEditDialog = (item) =>
      if @hasPermission()
        FormDialog.showFormDialog(editBoxTitle, entityManager, item)
        .then () =>
          @load()

    openDetailDialog = (id,type) =>
      if @hasPermission()
        detailEntityManager = entityManager

        if(type?)
          detailEntityManager = @helper.getEntityManager(type)

        if @hasPermission()
          $mdDialog.show
            templateUrl: detailEntityManager.getDetailModalTemplateUrl()
            controller: 'DataTableDetailModalCtrl'
            controllerAs: 'detailCtrl'
            skipHide: true
            locals:
              id: id
              entityManager: detailEntityManager

    openDetailEditDialog = (rowIndex, row, item, key) =>
      if @hasPermission()
        action = @detailEditActions[key][0]
        $log.debug 'action', action
        action.entityManager.setParentId(row.id)
        FormDialog.showFormDialog(action.title, action.entityManager, item)
          .then  =>
            entityManager.one(row.id)
              .then (data) =>
                _.extend(@items[rowIndex], data)

    openAuditDialog = (item) =>
      if not @hasAuditPermission()
        return

      object_id = entityManager.getAuditObjectId item

      MundoActions.execute 'audit_logs',
        data:
          object_id: object_id

    deleteSelected = =>
      if @hasPermission()
        ConfirmBox.showConfirmDialog(deleteBoxTitle, deleteBoxBody)
        .then =>
          removeSelected(@selected)
          .then =>
            @load()

    deleteSingle = (id) ->
      if @hasPermission()
        ConfirmBox.showConfirmDialog(deleteBoxTitle, deleteBoxBody)
        .then =>
          removeSingle(id)
          .then =>
            @load()

    removeSelected = (selected) ->
      $q.all _.map selected, (item) ->
        removeSingle(item.id)

    removeSingle = (id) ->
      entityManager.delete(id)

#   print
    getCsvContent = =>
      content = []
      for sItem in @selected
        obj = {}
        for item in @schema
          $log.debug item
          obj[item.key] = @getValue(sItem, item.key, @isDefined(item.multiple), item.type)
        content.push(obj)
      content

    getCsvHeader = =>
      item.title for item in @schema

    showInlineDetail = (rowIndex, row) =>
      if row.expanded
        row.expanded = false
      else
        @promise = entityManager.one(row.id)
          .then (data) =>
            _.extend(@items[rowIndex], data)
        row.expanded = true
      return true

    getDetailTemplate = () ->
      @detailTemplate

    init()

    $rootScope.$on('searchFor', (event,args) =>
      if pageTitle == args.to
        @openSearch()
        @query.search.query = args.term
        @onSearch()
    )

angular
  .module('mundoComponents')
  .controller 'DataTableCtrl', DataTableCtrl
